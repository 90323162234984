@import url(https://fonts.googleapis.com/css?family=Montserrat:400,600,700);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,800);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



html, body{
  width: 100%;
  height: 100%;
}


body{
  background-color:#f7f7f7;
}

html{
  background-color:#FFCE9F;
}


.wrapper{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
 
}

body {
  height: 100%;
}


.content-wrapper{
  width: 100%;
  min-height:calc(100vh - 90px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f7f7f7;
 
}


.expandLessMoreButton{
  padding:4px!important;
}

.container {
  height: 100%;
  min-width:100px;
  width:910px;
  max-width:100vw;
  overflow:hidden!important;
  /*white-space: nowrap;*/

}

.inner-container{
  height:100%
}


.jobitemInnerContainer{
  transition: height 1s ease;
}

.jobitem{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  scroll-margin: 13px;

}


.companyplaceholeritem{
  width:131px;
  height:131px;
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  scroll-margin: 13px;
  position: relative;
}

.companyitem{
  width:131px;
  height:131px;
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  scroll-margin: 13px;
  position: relative;
}


.companyitemcontainer::after {
  content: "";
  flex: auto;
}

.companyitem_inner {
  cursor:pointer;
  padding:5px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 121px;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.companyitem_img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.companyitem_caption {
  display: block;
}


.companysearch{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  height:40px;
}

.jobsearch{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  height:40px;
}

.breakFlex {
  flex-basis: 100%;
  height: 0;
}

.jobfilter{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  height:40px;
}

.jobsort{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  height:40px;
}

.App-logo{
  margin-top:13px;
  margin-left:15px;
  margin-bottom:5px;
  

}


.App-desc{
  margin-top:-3px;
  margin-left:14px;
  margin-bottom:5px;

  /*  color:#84684C;*/
  color:#84684C;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.01071em;


}

.App-logo-icon{
  margin-left:15px;
  margin-bottom:10px;
  height:40px;
}

.App-logo-image{
  height:45px;
}

body { overflow-y: scroll; }

.App-bar-items{
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-shrink: 0;
}



.App-bar {
  /*https://cssgradient.io/*/
  /*background-color: #F7B271;*/


  background: rgb(155, 140, 126);
  background: linear-gradient(0deg, rgba(255,206,159,1) 0%, rgba(247,178,113,1) 73%);

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;


}




.App-Info{
  background:#FFCE9F;


  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  height:30px;
  max-height:30px;
  overflow:hidden;
  width:100%;
}


.App{
  min-height:100%
}


.Settings-Button{

  font-size: 45px;
  position: fixed;
  right: 5px;
  bottom: 0;
  margin: 30px;
  border-radius: 50%;
  border: none;
  transition: 0.4s opacity ease;
  cursor: pointer;
  outline: none;
  background: transparent;
}


.not-found > .text-container {
    text-align: center;
  
    margin: 20px 15px;
  }
.not-found > .error-container {
  text-align: center;
  font-size: 180px;
  font-family: 'Catamaran', sans-serif;
  font-weight: 800;
  margin: 20px 15px;
}
.not-found > .error-container > span {
  display: inline-block;
  line-height: 0.7;
  position: relative;
  color: #FFB485;
}
.not-found > .error-container > span {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.not-found > .error-container > span:nth-of-type(1) {
  color: #D1F2A5;
  -webkit-animation: colordancing 4s infinite;
          animation: colordancing 4s infinite;
}
.not-found > .error-container > span:nth-of-type(3) {
  color: #F56991;
  -webkit-animation: colordancing2 4s infinite;
          animation: colordancing2 4s infinite;
}
.not-found > .error-container > span:nth-of-type(2) {
  width: 120px;
  height: 120px;
  border-radius: 999px;
}
.not-found > .error-container > span:nth-of-type(2):before,
.not-found > .error-container > span:nth-of-type(2):after {
	border-radius: 0%;
	content:"";
	position: absolute;
	top: 0; left: 0;
	width: inherit; height: inherit;
  border-radius: 999px;
	box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  -webkit-animation: shadowsdancing 4s infinite;
          animation: shadowsdancing 4s infinite;
}
.not-found > .error-container > span:nth-of-type(2):before {
transform: rotate(45deg);
}

.not-found > .screen-reader-text {
    position: absolute;
    top: -9999em;
    left: -9999em;
}
@-webkit-keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
				inset 0 30px 0 rgba(209, 242, 165, 0.4),
				inset -30px 0 0 rgba(239, 250, 180, 0.4),	
				inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
     box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
				inset 0 30px 0 rgba(245, 105, 145, 0.4),
				inset -30px 0 0 rgba(209, 242, 165, 0.4),	
				inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
   box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
				inset 0 30px 0 rgba(255, 196, 140, 0.4),
				inset -30px 0 0 rgba(245, 105, 145, 0.4),	
				inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@keyframes shadowsdancing {
  0% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
  25% {
    box-shadow: inset 30px 0 0 rgba(245, 105, 145, 0.4),
				inset 0 30px 0 rgba(209, 242, 165, 0.4),
				inset -30px 0 0 rgba(239, 250, 180, 0.4),	
				inset 0 -30px 0 rgba(255, 196, 140, 0.4);
  }
  50% {
     box-shadow: inset 30px 0 0 rgba(255, 196, 140, 0.4),
				inset 0 30px 0 rgba(245, 105, 145, 0.4),
				inset -30px 0 0 rgba(209, 242, 165, 0.4),	
				inset 0 -30px 0 rgba(239, 250, 180, 0.4);
  }
  75% {
   box-shadow: inset 30px 0 0 rgba(239, 250, 180, 0.4),
				inset 0 30px 0 rgba(255, 196, 140, 0.4),
				inset -30px 0 0 rgba(245, 105, 145, 0.4),	
				inset 0 -30px 0 rgba(209, 242, 165, 0.4);
  }
  100% {
    box-shadow: inset 30px 0 0 rgba(209, 242, 165, 0.4),
				inset 0 30px 0 rgba(239, 250, 180, 0.4),
				inset -30px 0 0 rgba(255, 196, 140, 0.4),	
				inset 0 -30px 0 rgba(245, 105, 145, 0.4);
  }
}
@-webkit-keyframes colordancing {
  0% {
    color: #D1F2A5;
  }
  25% {
    color: #F56991;
  }
  50% {
    color: #FFC48C;
  }
  75% {
    color: #EFFAB4;
  }
  100% {
    color: #D1F2A5;
  }
}
@keyframes colordancing {
  0% {
    color: #D1F2A5;
  }
  25% {
    color: #F56991;
  }
  50% {
    color: #FFC48C;
  }
  75% {
    color: #EFFAB4;
  }
  100% {
    color: #D1F2A5;
  }
}
@-webkit-keyframes colordancing2 {
  0% {
    color: #FFC48C;
  }
  25% {
    color: #EFFAB4;
  }
  50% {
    color: #D1F2A5;
  }
  75% {
    color: #F56991;
  }
  100% {
    color: #FFC48C;
  }
}
@keyframes colordancing2 {
  0% {
    color: #FFC48C;
  }
  25% {
    color: #EFFAB4;
  }
  50% {
    color: #D1F2A5;
  }
  75% {
    color: #F56991;
  }
  100% {
    color: #FFC48C;
  }
}



