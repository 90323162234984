

html, body{
  width: 100%;
  height: 100%;
}


body{
  background-color:#f7f7f7;
}

html{
  background-color:#FFCE9F;
}


.wrapper{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
 
}

body {
  height: 100%;
}


.content-wrapper{
  width: 100%;
  min-height:calc(100vh - 90px);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f7f7f7;
 
}


.expandLessMoreButton{
  padding:4px!important;
}

.container {
  height: 100%;
  min-width:100px;
  width:910px;
  max-width:100vw;
  overflow:hidden!important;
  /*white-space: nowrap;*/

}

.inner-container{
  height:100%
}


.jobitemInnerContainer{
  -moz-transition: height 1s ease;
  -webkit-transition: height 1s ease;
  -o-transition: height 1s ease;
  transition: height 1s ease;
}

.jobitem{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  scroll-margin: 13px;

}


.companyplaceholeritem{
  width:131px;
  height:131px;
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  scroll-margin: 13px;
  position: relative;
}

.companyitem{
  width:131px;
  height:131px;
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  scroll-margin: 13px;
  position: relative;
}


.companyitemcontainer::after {
  content: "";
  flex: auto;
}

.companyitem_inner {
  cursor:pointer;
  padding:5px;
  vertical-align: top;
  display: inline-block;
  text-align: center;
  width: 121px;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.companyitem_img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.companyitem_caption {
  display: block;
}


.companysearch{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  height:40px;
}

.jobsearch{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  height:40px;
}

.breakFlex {
  flex-basis: 100%;
  height: 0;
}

.jobfilter{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  height:40px;
}

.jobsort{
  margin-left:10px;
  margin-right:10px;
  margin-top:15px;
  height:40px;
}

.App-logo{
  margin-top:13px;
  margin-left:15px;
  margin-bottom:5px;
  

}


.App-desc{
  margin-top:-3px;
  margin-left:14px;
  margin-bottom:5px;

  /*  color:#84684C;*/
  color:#84684C;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  letter-spacing: 0.01071em;


}

.App-logo-icon{
  margin-left:15px;
  margin-bottom:10px;
  height:40px;
}

.App-logo-image{
  height:45px;
}

body { overflow-y: scroll; }

.App-bar-items{
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-shrink: 0;
}



.App-bar {
  /*https://cssgradient.io/*/
  /*background-color: #F7B271;*/


  background: rgb(155, 140, 126);
  background: linear-gradient(0deg, rgba(255,206,159,1) 0%, rgba(247,178,113,1) 73%);

  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;


}




.App-Info{
  background:#FFCE9F;


  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  height:30px;
  max-height:30px;
  overflow:hidden;
  width:100%;
}


.App{
  min-height:100%
}


.Settings-Button{

  font-size: 45px;
  position: fixed;
  right: 5px;
  bottom: 0;
  margin: 30px;
  border-radius: 50%;
  border: none;

  -webkit-transition: 0.4s opacity ease;
  transition: 0.4s opacity ease;
  cursor: pointer;
  outline: none;
  background: transparent;
}

